<template>
  <div>
    <mia-navback @back="back" :isShowSave="!isView" @submit="submitForm" />
    <div class="header">
      <el-form :inline="true">
        <el-form-item label="台账编号:">
          {{ hygieneLedger.no }}
        </el-form-item>
        <el-form-item v-if="hygieneLedger.updateDate" label="修改时间:">
          {{ hygieneLedger.updateDate }}
        </el-form-item>
        <el-form-item v-if="hygieneLedger.updateUserName" label="最后修改人:">
          {{ hygieneLedger.updateUserName }}
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <table>
        <caption style="font-size: 24px; font-weight: 600; margin-bottom: 20px">
          农药经营环境卫生管理记录
        </caption>
        <tbody>
          <tr>
            <td colspan="3">{{ year }}年{{ month }}月环境卫生管理检查记录</td>
          </tr>
          <tr>
            <td colspan="3">
              <span>检查时间：</span>
              <el-date-picker
                v-model="hygieneLedger.checkDate"
                :disabled="isView"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td>检查内容及项目</td>
            <td>检查情况</td>
            <td>处置措施</td>
          </tr>
          <tr v-for="(item, index) in safeItems" :key="index">
            <td style="text-align: left">{{ item.name }}</td>
            <td>
              <el-checkbox
                true-label="0"
                false-label=""
                :disabled="isView"
                v-model="item.value"
                @change="valChange($event, item.name, '0')"
                >好</el-checkbox
              >
              <el-checkbox
                true-label="1"
                false-label=""
                :disabled="isView"
                v-model="item.value"
                @change="valChange($event, item.name, '1')"
                >较好</el-checkbox
              >
              <el-checkbox
                true-label="2"
                false-label=""
                :disabled="isView"
                v-model="item.value"
                @change="valChange($event, item.name, '2')"
                >一般</el-checkbox
              >
              <el-checkbox
                true-label="3"
                false-label=""
                :disabled="isView"
                v-model="item.value"
                @change="valChange($event, item.name, '3')"
                >差</el-checkbox
              >
            </td>
            <td>
              <el-checkbox
                true-label="0"
                false-label=""
                :disabled="isView"
                v-model="item.method"
                @change="val1Change($event, item.name, '0')"
                >当场落实</el-checkbox
              >
              <el-checkbox
                true-label="1"
                false-label=""
                :disabled="isView"
                v-model="item.method"
                @change="val1Change($event, item.name, '1')"
                >未落实</el-checkbox
              >
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <el-row>
                <el-col :span="8" style="display: flex; align-items: center">
                  <span>管理检查负责人（签名）：</span>
                  <div v-if="isView" style="display: inline-block">
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="getImg(hygieneLedger.checkSign)"
                      fit="contain"
                    ></el-image>
                  </div>
                  <div v-else style="display: inline-block" @click="openSign">
                    <div v-if="!hygieneLedger.checkSign" class="sign">
                      点击签名
                    </div>
                    <el-image
                      v-else
                      style="width: 100px; height: 100px"
                      fit="contain"
                      :src="getImg(hygieneLedger.checkSign)"
                    ></el-image>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" style="display: flex; align-items: center">
                  <span>改正措施落实负责人（签名）：</span>
                  <div v-if="isView" style="display: inline-block">
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="getImg(hygieneLedger.executeSign)"
                      fit="contain"
                    ></el-image>
                  </div>
                  <div
                    v-else
                    style="display: inline-block"
                    @click="openExecuteSign"
                  >
                    <div v-if="!hygieneLedger.executeSign" class="sign">
                      点击签名
                    </div>
                    <el-image
                      v-else
                      style="width: 100px; height: 100px"
                      fit="contain"
                      :src="getImg(hygieneLedger.executeSign)"
                    ></el-image>
                  </div>
                </el-col>
                <el-col :span="16" style="text-align: right">
                  <span>落实时间：</span>
                  <el-date-picker
                    v-model="hygieneLedger.checkDate"
                    :disabled="isView"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-col>
              </el-row>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Sign
      :visible="signatureVisible"
      @finsih="signFisish"
      @close="signatureVisible = false"
    ></Sign>
    <Sign
      :visible="executeSignatureVisible"
      @finsih="executeSignFisish"
      @close="executeSignatureVisible = false"
    ></Sign>
  </div>
</template>
<script>
import Sign from "@/components/Sign";
import { uploadFile } from "@/api/common";
import { imgUrl } from "../../../../settting";
import {
  getHygieneLedgerCode,
  getHygieneLedgerDetails,
  addHygieneLedger,
  editHygieneLedger,
} from "@/api/hygieneLedger";
import moment from "moment";
export default {
  components: { Sign },
  data() {
    return {
      hygieneLedger: {},
      checkPlace: [],
      isView: false,
      signatureVisible: false,
      executeSignatureVisible: false,
      safeItems: [
        {
          name: "经营场所环境卫生",
          value: "1",
          method: "",
        },
        {
          name: "仓库场所环境卫生",
          value: "1",
          method: "",
        },
        {
          name: "经营行业文明规范",
          value: "1",
          method: "",
        },
        {
          name: "其他规范",
          value: "1",
          method: "",
        },
      ],
    };
  },
  computed: {
    year() {
      return this.hygieneLedger.checkDate.split("-")[0];
    },
    month() {
      return this.hygieneLedger.checkDate.split("-")[1];
    },
  },
  methods: {
    init(id, view) {
      if (id) {
        getHygieneLedgerDetails(id).then((res) => {
          if (res.code === 1) {
            this.hygieneLedger = res.data;

            this.safeItems = JSON.parse(res.data.checkText);
            this.isView = view;
          }
        });
      } else {
        getHygieneLedgerCode().then((res) => {
          if (res.code == 1) {
            this.hygieneLedger = {
              ...this.hygieneLedger,
              no: res.data,
              checkDate: moment().format("YYYY-MM-DD"),
            };
          }
        });
      }
    },
    getImg(url) {
      return imgUrl + url;
    },
    valChange(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.value = val;
          }
          return v;
        });
      } else {
        if (val === "3") {
          this.safeItems = this.safeItems.map((v) => {
            if (name === v.name) {
              v.method = "0";
            }
            return v;
          });
        }
      }
    },
    val1Change(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.method = val;
          }
          return v;
        });
      }
    },
    back() {
      this.$emit("back");
    },
    checkPlaceChange(val) {
      this.checkPlace = val;
    },
    submitForm() {
      if (!this.hygieneLedger.checkSign) {
        return this.$message.error("请管理检查负责人签字");
      }
      if (!this.hygieneLedger.executeSign) {
        return this.$message.error("请措施落实责任人签字");
      }

      this.hygieneLedger.checkText = JSON.stringify(this.safeItems);
      if (this.hygieneLedger.id) {
        editHygieneLedger(this.hygieneLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        addHygieneLedger(this.hygieneLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    openSign() {
      this.signatureVisible = true;
    },
    signFisish(val) {
      const imgs = this.base64ImgtoFile(val);
      const formData = new FormData();
      formData.append("file", imgs);
      uploadFile(formData).then((res) => {
        if (res.code === 1) {
          this.hygieneLedger = { ...this.hygieneLedger, checkSign: res.url };
        }
      });
      this.signatureVisible = false;
    },
    openExecuteSign() {
      this.executeSignatureVisible = true;
    },
    executeSignFisish(val) {
      const imgs = this.base64ImgtoFile(val);
      const formData = new FormData();
      formData.append("file", imgs);
      uploadFile(formData).then((res) => {
        if (res.code === 1) {
          this.hygieneLedger = { ...this.hygieneLedger, executeSign: res.url };
        }
      });
      this.executeSignatureVisible = false;
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 80%;
  margin: 0 auto;
  padding: 20px 50px;
  /deep/ .el-form-item {
    width: 32%;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff;
  color: #409eff;
}
.content {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
table {
  width: 100%;
}
.inline-table {
  border: 0px;
}
table,
td {
  border-collapse: collapse;
  border: 1px solid #999;
}
td {
  padding: 15px;
  font-size: 14px;
}
.sign {
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
}
</style>
